import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`What are Verbs in Arabic Grammar?`}</h2>
    <p>{`Verbs in Arabic Grammar represents `}<em parentName="p">{`an Action`}</em>{`. It basically informs us about two properties`}</p>
    <ul>
      <li parentName="ul">{`Doer of Action (فاعل or حدث)`}</li>
      <li parentName="ul">{`Time when that Action took place (زمن)`}</li>
    </ul>
    <h2>{`What are Kinds Verbs in Arabic Grammar?`}</h2>
    <p>{`There are three kinds of verbs in Arabic Grammar,`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`فعل ماض`}</strong>{` It represents Past Tense`}</li>
      <li parentName="ul"><strong parentName="li">{`فعل مضارع`}</strong>{` It represents Present and Future Tense`}</li>
      <li parentName="ul"><strong parentName="li">{`فعل أمر`}</strong>{` It is Imperative i.e. commanding verb`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "641px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.306748466257666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPklEQVQoz41RbW+CQAzm//+XRaZuc3Mvis5kIWBwgyC6EVSYLNPsg4y7672soGIyXbInzXFtn/ZpD00dIfxgbgyjKBu4y8dR4oQbdN9SqqT8xdSOixdrmKY8SPLhZGV6736coTtfMaX+UYwCXEgAgcZocXL05TGxLN7Gq6xAALILcM4xzqGIqT2tMg0lJCawM34YYxQKnZKKopwyVXYRXADDSWCnUjbWvs2nWfv6Ra+FravctmCTYeprOvFrZ36zPm42grq+9v2Cz9hnv+c3zl1dDy4aeNeIM0wH/eCmtTA61H0WhCCPJnFqdML729e79tLo5PNZUQyQOcOk1x23LufdB7xru5m3k+wX+wtSysPYQpx4MFw8zwmunhNCGSNohADAocXJX1U2VnEcm6YZRZFt257nOY5jWRYGK0KFH5lMOPSvIaB7AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Verbs in Arabic Grammar",
          "title": "Verbs in Arabic Grammar",
          "src": "/static/f5dbcafa5d755842e251fe2af4080e17/c7dcc/verbs-in-arabic-grammar.png",
          "srcSet": ["/static/f5dbcafa5d755842e251fe2af4080e17/222b7/verbs-in-arabic-grammar.png 163w", "/static/f5dbcafa5d755842e251fe2af4080e17/ff46a/verbs-in-arabic-grammar.png 325w", "/static/f5dbcafa5d755842e251fe2af4080e17/c7dcc/verbs-in-arabic-grammar.png 641w"],
          "sizes": "(max-width: 641px) 100vw, 641px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`We will learn Arabic Verbs briefly and will try to make `}<strong parentName="p">{`Arabic verb easy`}</strong>{` (إن شاء الله)`}<br parentName="p"></br>{`
`}{`Lets begin with first kind`}</p>
    <h2>{`Past Tense Verb in Arabic Grammar (فعل ماض)`}</h2>
    <p>{`This verb represents an action that took place in the past. `}</p>
    <br />
    <p><strong parentName="p">{`Arabic Past tense Verb examples`}</strong>{`  `}</p>
    <ul>
      <li parentName="ul">{`ذهب زيد (Zaid went)`}</li>
      <li parentName="ul">{`دخل زيد (Zaid entered)`}</li>
    </ul>
    <br />
    <p><strong parentName="p">{`Basic Arabic Verb Conjugation Chart/Table in Past tense`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`Verb Table`}</em></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Singular`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Dual`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Plural`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Third Person (Male)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`كَتَبَ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`كَتَبَا`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`كَتَبُوا`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Third Person (Female)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`كَتَبَتْ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`كَتَبَتَا`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`كَتَبْنَ`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Second Person (Male)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`كَتَبْتَ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`كَتَبْتُمَا`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`كَتَبْتُمْ`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Second Person (Female)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`كَتَبْتِ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`كَتَبْتُمَا`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`كَتَبْتُنَّ`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`First Person`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`كَتَبْتُ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`كَتَبْنَا`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`How to identify past tense Verb in Arabic Grammar (ما علامة فعل ماض)`}</h3>
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Use of أمسِ`}</strong></p>
    <ul>
      <li parentName="ul">{`For example: ذهبتُ أمسِ`}</li>
      <li parentName="ul">{`This is not a very good علامة because `}
        <ul parentName="li">
          <li parentName="ul">{`It can be used in فعل مضارع also e.g. لم يذهب زيد إلى البيت `}<strong parentName="li">{`أمسِ`}</strong></li>
          <li parentName="ul">{`At times it can't be used in فعل ماض e.g. إن حضر زيد أكرمته`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`Rules of past tense Verb in Arabic Grammar (الحكم الفعل الماضي)`}</h3>
    <p>{`There are 3 states in which فعل ماض exists,`}</p>
    <ul>
      <li parentName="ul">{`فعل ماض having Fatha in the end (يبنى الفعل الماضي على الفتح)`}
        <ul parentName="li">
          <li parentName="ul">{`This is the default case of فعل ماض`}</li>
          <li parentName="ul">{`Example: كت`}<strong parentName="li">{`بَ`}</strong>{` الطالب الدرس`}</li>
        </ul>
      </li>
      <li parentName="ul">{`فعل ماض having Damma in the end (يبنى الفعل الماضي على الضم)`}
        <ul parentName="li">
          <li parentName="ul">{`This state comes with واو الجماعة in the end. e.g. الطلاب كت`}<strong parentName="li">{`بُ`}</strong>{`وا الدرس`}</li>
        </ul>
      </li>
      <li parentName="ul">{`فعل ماض having Sukoon in the end`}
        <ul parentName="li">
          <li parentName="ul">{`This state comes with ضمير رفع متحرك`}</li>
          <li parentName="ul">{`Side Note ضمير رفع متحرك comes with`}
            <ul parentName="li">
              <li parentName="ul">{`تاء الفاعل e.g. كت`}<strong parentName="li">{`بْ`}</strong>{`تُ الدرس `}</li>
              <li parentName="ul">{`نا الفاعلين e.g. كت`}<strong parentName="li">{`بْ`}</strong>{`نَا الدرس `}</li>
              <li parentName="ul">{`نون النسوة e.g. الطالبات كت`}<strong parentName="li">{`بْ`}</strong>{`نَ الدرس`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h2>{`Present/Future Tense Verb in Arabic Grammar (فعل مضارع)`}</h2>
    <p>{`فعل مضارع is used to express an action in present or an action that will happen in the future.  `}</p>
    <br />
    <p><strong parentName="p">{`Arabic Past tense Verb examples`}</strong>{`  `}</p>
    <ul>
      <li parentName="ul">{`يَدْخُل (He enters)`}</li>
      <li parentName="ul">{`يكتب (He writes)`}</li>
    </ul>
    <br />
    <p><strong parentName="p">{`Basic Arabic Verb Conjugation Chart/Table in Present tense`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`Verb Table`}</em></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Singular`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Dual`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Plural`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Third Person (Male)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`يَكْتُبُ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`يَكْتُبَانِ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`يَكْتُبُونَ`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Third Person (Female)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`تَكْتُبُ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`تَكْتُبَانِ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`يَكْتُبْنَ`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Second Person (Male)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`تَكْتُبُ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`تَكْتُبَانِ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`تَكْتُبُونَ`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Second Person (Female)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`تَكْتُبِينَ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`تَكْتُبَانِ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`تَكْتُبْنَ`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`First Person`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`أَكْتُبُ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`نَكْتُبُ`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`How to identify present tense Verb in Arabic Grammar (ما علامة فعل مضارع)`}</h3>
    <ul>
      <li parentName="ul">{`فعل مضارع `}<strong parentName="li">{`Always`}</strong>{` begins with words ن – أ - ي - ت which is also called as `}<strong parentName="li">{`أحرف المضارعة`}</strong>{` which is put in one word نأيت.`}</li>
      <li parentName="ul">{`One point to note is these are `}<em parentName="li">{`NOT 100% sure علامات`}</em>{` of فعل مضارع because there are nouns also that begin with ن – أ - ي - ت.`}</li>
    </ul>
    <h3>{`Rules of present tense Verb in Arabic Grammar (الحكم الفعل مضارع)`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`فعل مضارع is معرب`}</strong><br parentName="li"></br>
        <em parentName="li" {...{
          "className": "exception"
        }}>{`Exceptions`}</em>
        <ul parentName="li">
          <li parentName="ul">{`When added نون التوكيد e.g. لأكتبَنَّ`}
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p">{`لأكتبَنَّ`}</strong>{`: فعل مضارع مبني على الفتح`}</p>
            </blockquote>
          </li>
          <li parentName="ul">{`When we have نون النسوة e.g. النساء يكتبْن الدرس`}
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p">{`يكتبْن`}</strong>{`: فعل مضارع مبني على السكون`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
    </ul>
    <br />
    <ul>
      <li parentName="ul">
        <p parentName="li">{`فعل مضارع is مفتوحاً when فعل ماض is ثلاثياً OR خماسياً OR سداسياً but it is مضموماً when فعل ماض is رباعياً e.g. (زلزل, يُزلزل). `}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`It has to be 4 letters either with فعل مجرّد e.g. (وسوس, يُوسوس) or فعل مزيد e.g. (اقام ,يُقيم)`}</p>
            <table parentName="li">
              <thead parentName="table">
                <tr parentName="thead">
                  <th parentName="tr" {...{
                    "align": null
                  }}>{`Verb`}</th>
                  <th parentName="tr" {...{
                    "align": null
                  }}>{`Letters`}</th>
                </tr>
              </thead>
              <tbody parentName="table">
                <tr parentName="tbody">
                  <td parentName="tr" {...{
                    "align": null
                  }}>{`ذهب يَذهب`}</td>
                  <td parentName="tr" {...{
                    "align": null
                  }}>{`3`}</td>
                </tr>
                <tr parentName="tbody">
                  <td parentName="tr" {...{
                    "align": null
                  }}>{`جاء يَجيء`}</td>
                  <td parentName="tr" {...{
                    "align": null
                  }}>{`3`}</td>
                </tr>
                <tr parentName="tbody">
                  <td parentName="tr" {...{
                    "align": null
                  }}>{`التجى يَلتجي`}</td>
                  <td parentName="tr" {...{
                    "align": null
                  }}>{`5`}</td>
                </tr>
                <tr parentName="tbody">
                  <td parentName="tr" {...{
                    "align": null
                  }}>{`استجاش يَستجيش`}</td>
                  <td parentName="tr" {...{
                    "align": null
                  }}>{`6`}</td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
      </li>
    </ul>
    <h2>{`Imperative tense Verb in Arabic Grammar (فعل الأمر)`}</h2>
    <ul>
      <li parentName="ul">{`Imperative verb represents a command`}</li>
      <li parentName="ul">{`Imperative verb is made from present tense by making it makzoom and dropping the أحرف المضارعة which is (ن – أ - ي - ت) from it (فعل الأمر يُبْنَى على ما يُجْزَم به مضارعٌه)`}
        <ul parentName="li">
          <li parentName="ul">{`Example: يكتبُ is present tense and اكتبْ is فعل الأمر by dropping ي and adding Sukoon in the end.`}</li>
        </ul>
      </li>
    </ul>
    <br />
    <p><strong parentName="p">{`Arabic Imperative tense Verb examples`}</strong>{`  `}</p>
    <ul>
      <li parentName="ul">{`اكتبْ دَرسك (Write your lesson)`}</li>
      <li parentName="ul">{`اخرج من البيت (Get out of the house)`}</li>
    </ul>
    <br />
    <p><strong parentName="p">{`Basic Arabic Verb Conjugation Chart/Table in Imperative tense`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`Verb Table`}</em></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Singular`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Dual`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Plural`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Second Person (Male)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`اكتب`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`اكتبا`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`اكتبو`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Second Person (Female)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`اكتبي`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`اكتبا`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`اكتبن`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Rules of Imperative Verb in Arabic Grammar (الحكم الفعل الأمر)`}</h3>
    <ul>
      <li parentName="ul">{`Fixed on Sukoon (مبني على السكون)`}
        <ul parentName="li">
          <li parentName="ul">{`Example: اكتبْ دَرسك`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Fixed on dropped Noon (مبني على حذف النون)`}
        <ul parentName="li">
          <li parentName="ul">{`When الفعل المضارع is from the الأفعال الخمسة i.e. يكتبان تكتبان يكتبون تكتبون تكتبين then فعل الأمر will be`}
            <ul parentName="li">
              <li parentName="ul">{`اكتبا الدرس`}</li>
              <li parentName="ul">{`اكتبوا الدرس`}</li>
              <li parentName="ul">{`اكتبي الدرس`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Fixed on dropped Weak letter (مبني على حذف حرف العلة)`}
        <ul parentName="li">
          <li parentName="ul">{`When الفعل المضارع has Weak letter (حرف العلة) at the end of the verb e.g. يسعى OR يقضي OR يدعو then فعل الأمر will be`}
            <ul parentName="li">
              <li parentName="ul">{`اسعَ`}</li>
              <li parentName="ul">{`اقضِ`}</li>
              <li parentName="ul">{`ادعُ`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h3>{`Special situations in فعل الأمر`}</h3>
    <ul>
      <li parentName="ul">{`Meeting of two sakins (الالتقاء ساكنين)`}
        <ul parentName="li">
          <li parentName="ul">{`In Arabic language two sakin words can't come together. So to mitigate this situation we add Kasra at the end of فعل الأمر e.g. اكت`}<strong parentName="li">{`بِ`}</strong>{` `}<strong parentName="li">{`الْ`}</strong>{`درس`}</li>
        </ul>
      </li>
      <li parentName="ul">{`فعل الأمر from فعل اجوف`}
        <ul parentName="li">
          <li parentName="ul">{`E.g. خاف  يخاف Amr (الأمر) will be خفْ `}</li>
          <li parentName="ul">{`E.g. أجاد يجيد Amr (الأمر) will be أجدْ`}</li>
        </ul>
      </li>
    </ul>
    <br />
    <p>{`Verbs in Arabic grammar at first glance looks quite complex and overwhelming with their conjugation rules. You may follow the following simple guidelines to nail verbs in Arabic grammar,`}</p>
    <ul>
      <li parentName="ul">{`Start with learning simple verbs like ضرب، فتح etc`}</li>
      <li parentName="ul">{`Whenever you see a new verb write it down and conjugate it at least once.`}</li>
      <li parentName="ul">{`Conjugate 2 verbs every day `}<strong parentName="li">{`NO MORE NO LESS`}</strong></li>
      <li parentName="ul">{`Learn different forms of verb slowly once you are comfortable with basic form`}</li>
      <li parentName="ul">{`Learn verbs with weak letters i.e. ا ي و in the end one you are comfortable with all the forms of verb`}</li>
      <li parentName="ul">{`Make a lot of Dua (And remember me in them as well :) )`}</li>
      <li parentName="ul">{`Give yourself time and إن شاء الله you will slowly grasp verbs in Arabic Grammar`}</li>
    </ul>
    <h2>{`Verbs in Arabic Grammar Reference(s)`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.qutoofacademy.com/",
          "rel": "nofollow noopener"
        }}>{`Qutoof Academy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cooljugator.com/ar",
          "rel": "nofollow noopener"
        }}>{`cooljugator.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://acon.baykal.be/index.php?r3=%D8%A8&r2=%D8%AA&r1=%D9%83&type=I&pvowel=a&ivowel=u&display=traditional",
          "rel": "nofollow noopener"
        }}>{`acon.baykal.be`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      